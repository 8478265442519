table {
  border-collapse: collapse;
  /* width: 100%; */
}

#td {
  border: 1px solid #ddd;
  padding: 6px;
}

#tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tr:hover {
  background-color: #ddd;
}

/* table th, */
#tfoot {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #e6b412;
}
